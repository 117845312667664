.invitepage-container {
  display: flex;
  flex-direction: column;
  padding: 60px 16px;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  @supports (height: 100dvh) {
    min-height: 100dvh;
  }
  background: #f6f5fa;
  gap: 16px;
}

.greeting-form-container {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.greeting-form {
  width: 390px;
  max-width: 100%;
  padding: 16px;
  padding-bottom: 15vh;
}

@media screen and (min-width: 900px) {
  .greeting-form {
    padding-bottom: 16px;
  }
}

.greeting-form .greeting-form-internal {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.greeting-form .title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.28;
  letter-spacing: 0.37px;
  color: #00004e;
  margin: 0;
  word-break: break-word;
}

.greeting-form .inputs-bday input {
  height: 60px;
  padding: 18px 16px 5px;
  border-radius: 10px;
  border: none;
  font-size: 20px;
  line-height: 1.25;
  background-color: white;
  box-shadow: 8px 0px 16px 0px rgba(0, 0, 0, 0.04);
  caret-color: #007aff;
  font-family: 'SF Pro Rounded', sans-serif;
}

.greeting-form .inputs-bday input:focus,
.greeting-form .inputs-bday input:active {
  outline: none;
}

.greeting-form .inputs-bday {
  display: flex;
  gap: 10px;
}
.greeting-form .inputs-bday .input-wrapper:nth-child(1) {
  width: 75px;
  flex: 0 1 auto;
}
.greeting-form .inputs-bday .input-wrapper:nth-child(2) {
  width: 75px;
  flex: 0 1 auto;
}
.greeting-form .inputs-bday .input-wrapper:nth-child(3) {
  width: 188px;
  flex: 0 2 auto;
}
.greeting-form .inputs-bday input {
  width: 100%;
}
.greeting-form .input-wrapper {
  position: relative;
}
.greeting-form .input-wrapper .float-placeholder {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 500;
  line-height: 1.25;
  color: #aeaeb2;
  transition: font-size 0.5s ease-in-out, top 0.5s ease-in-out;
  pointer-events: none;
}
.greeting-form
  .input-wrapper
  input:not(:placeholder-shown)
  + .float-placeholder,
.greeting-form .input-wrapper input:focus + .float-placeholder {
  font-size: 13px;
  top: 25%;
}

.greeting-form .submitBtn {
  width: 100%;
  height: 55px;
  padding: 10px;
  border-radius: 50px;
  background: linear-gradient(112.62deg, #8b8bff -20.38%, #7070ff 131.98%);
  font-size: 18px;
  font-weight: 600;
  border: none;
  color: #fff;
  cursor: pointer;
  line-height: 1.22;
}

.greeting-form .error-message {
  font-size: 13px;
  line-height: 16px;
  color: #ff5e71;
  position: absolute;
  bottom: 0;
  left: 16px;
  transform: translateY(calc(8px + 100%));
}

.invitepage-appstore-btn {
  flex: 0 0 auto;
  background: url("../../assets/svg/appstore.svg") no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  width: 120px;
  height: 40px;
}

.invitepage-logo-container {
  flex: 0 0 auto;
}

.answer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  max-width: 264px;
  padding: 10px;
}
.answer-container svg {
  width: 90px;
  height: auto;
  margin-bottom: 18px;
}
.answer-container .answer-title {
  color: #00004e;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.32;
  text-align: center;
  margin: 0;
}
.answer-container .answer-text {
  color: #475467;
  font-size: 17px;
  line-height: 1.47;
  text-align: center;
  margin: 0;
}
.answer-container .answer-text p {
  margin: 0;
}
.answer-container .answer-text p:not(:last-child) {
  margin-bottom: 0.5em;
}
