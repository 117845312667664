.postcard_wrapper {
  min-height: 800px;
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;
  align-items: center;
}

.postcard_container {
  width: 100%;
  height: 100%;
  max-width: 40%;
  max-height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.postcard_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;
  align-self: center;
}

#book {
  width: 350px;
  height: 500px;
  position: absolute;
  top: 195px;
  perspective: 75vw;
}

.d-flex {
  display: flex;
}

.startpage-container_postcard {
  align-items: flex-start;
  margin-bottom: 24px;
}

.page {
  width: 350px;
  height: 100%;
  background: rgba(241, 241, 241, 0.64);
  box-shadow: 5px 10px 10px rgba(178, 178, 178, 0.15);
  position: absolute;
  left: 0;
  top: 0;
  transition: z-index 0.01s linear 0.5s, transform 1s linear;
  transform-origin: 0 50%;
  z-index: 5;
  cursor: pointer;
  border-radius: 16px;
}

.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.1s linear 0.455s;
  border-radius: 16px;
}
.front {
  opacity: 1;
  background-repeat: no-repeat;
  background-size: cover !important;
}
.back {
  transform: scaleX(-1);
}

#book input {
  visibility: hidden;
}

#book label {
  width: 100%;
  height: 100%;
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}

#book input:checked + .page {
  transform: rotateY(-180deg);
}

#book input:checked + .page .front {
  opacity: 0;
}
#book input:checked + .page .back {
  opacity: 1;
}

#five:checked + .page {
  z-index: 5;
}
#one:checked + .page {
  z-index: 1;
}

#page1 .back {
  background: rgba(241, 241, 241, 0.64);
  animation: a 3s linear infinite;
}
@keyframes a {
  45% {
    color: white;
  }
  50% {
    color: #222;
  }
  55% {
    color: white;
  }
}

#page5 .front {
  background: #ccd5df;
}

.appStore-btn {
  width: 100%;
}

.appStore-btn button {
  width: 100%;
  padding: 16px 18px;
  border-radius: 12px;
  background: #000;
  border: none;
  color: #fff;
}
