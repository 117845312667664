html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  color: #101032;
  padding: 0;
  margin: 0;
  font-family: "SF Pro Rounded", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-wrapper {
  margin: 0 auto;
  max-width: 1440px;
}

.inner-padding{
  padding: 0 64px;
}

.flex-center{
  display: flex;
  align-items: center;
}

.justify-center{
  justify-content: center;
}

.justify-between{
  justify-content: space-between;
}

.h1-titles-48-600{
  font-size: 48px;
  font-weight: 600;
  margin: 0;
}

.fs-64-600{
  font-size: 64px;
  font-weight: 600;
  color: #0D0E14
}

.fs-40-600{
  font-size: 40px;
  font-weight: 600;
  color: #0D0E14
}

.text-center{
  text-align: center;
}

.topBar{
  /*height: 80px;*/
  display: flex;
  justify-content: space-between !important;
}

.navbar-toggler,
.navbar-toggler:focus{
  border: none;
  box-shadow: none;
}

.navbar-collapse{
  justify-content: flex-end;
}

.navbar-toggler-icon{
  background-image: url("assets/svg/burger.svg");
}

.nav-item{
  padding: 12px;
  font-weight: 500;
}

.nav-item a{
  text-decoration: none;
  color: inherit;
}

.marked{
  background-color: #5E5EFF;
  color: #fff;
  border-radius: 8px;
}

.main-screen{
  display: flex;
  justify-content: space-evenly;
  height: calc(100vh - 80px);
  padding-left: 64px;
}

.main-item{
  flex: 1 1 50%;
}

.main-item h1{
  font-size: 64px;
  font-weight: 600;
  margin-bottom: 0;
}

.main-item img{
  width: 100%;
}

.main-right{
  justify-content: center;
}
.hide-button{
  display: inline-block;
}

.explore-features-btn{
  width: 154px;
  padding: 16px 12px;
  border: 1.5px solid #2BD6BC;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  margin-top: 16px;
}

.main-item a{
  text-decoration: none;
  color: #2BD6BC;
}

.features-block{
  background-color: #FFFAF8;
  padding: 0 15px 104px;
  margin-bottom: 96px;
}

.features-block h1{
  font-size: 64px;
  font-weight: 600;
  margin: 0;
}

.features-title{
  padding: 80px 0 64px;
  text-align: center;
}

.items-block{
  justify-content: space-evenly;
}

.feature-item{
  display: flex;
  flex-direction: column;
  width: 256px;
  align-items: center;
}

.feature-item img{
  padding: 32px 40px;
}

.feature-item h1{
  font-size: 36px;
  font-weight: 600;
}

.feature-item p{
  color: #58586F;
}

.item-img-container{
  width: 144px;
  height: 144px;
  background-color: #fff;
  border-radius: 32px;
  margin-bottom: 16px;
}

.item-text{
  text-align: center;
}

.gifting-calendar{
  display: flex;
  padding-top: 104px;
  margin-bottom: 96px;
}

.benefit-2{
  padding-top: 0;
}

.benefit-2 .benefit-text{
  margin-left: 40px;
}

.benefit-item-2{
  padding-right: 20px;
}

.web-image{
  display: block;
}

.mob-image{
  display: none;
}

.purple-bg{
  background-color: #CFCFFF;
}

.lightGreen-bg{
  background-color: #BFF3EB;
}

.peach-bg{
  background-color: #FFE3D4;
}

.strange-bg{
  background-color: #EBF8F9;
}

.gifting-calendar-item img{
  height: 100%;
  width: 75%;
}

.gifting-calendar-item{
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
}

.benefit-2-pb{
  padding-bottom: 104px
}

.benefit-text{
  margin-left: 176px;
}

.dload-btn{
  width: 152px;
  height: 44px;
}

.apps-block{
  margin-top: 6px;
  margin-bottom: 24px;
  /*padding: 0 15px;*/
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.appStore{
  background: url('assets/svg/appstore.svg') no-repeat;
  background-size: contain;
  margin-top: 10px;
  margin-right: 32px;
}

.googlePlay{
  background: url('assets/svg/googleplay.svg') no-repeat;
  background-size: contain;
  margin-top: 10px;
}

#download .download-benefit-item{
  margin-right: 70px;
}

.footer{
  display: flex;
  flex-direction: column;
  color: #58586F;
  justify-content: space-between;
}

.nav-links{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.divider{
  border-top: 1px solid #CCD5DF;
  margin-top: 31px;
}

.privacy, .terms{
  max-width: 65%;
  color: #4B5567;
  padding-left: 64px;
  overflow-wrap: break-word;
  margin-bottom: 80px;
}

.privacy h2, .terms h2, .terms h3{
  color: #0D0E14;
  font-weight: 600;
}

.privacy b, .terms b{
  font-weight: 500;
}

.privacy li, .terms li{
  margin-bottom: 10px;
}

.privacy a, .terms a{
  color: inherit;
}

.privacy .text-section,
.terms .text-section{
  margin: 1em 0;
}

.contact-us{
  min-height: 600px;
  margin: 180px 0;
}

.contact-us h1{
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
}

.contact-us form{
  width: 100%;
  margin-top: 26px;
}

.contact-us input,
.contact-us input:focus,
.contact-us textarea,
.contact-us textarea:active{
  border-radius: 8px;
  padding: 10px 14px;
  border: 1px solid #ECECF5;
}

input::placeholder{
  font-family: "SF Pro Rounded", sans-serif;
}

/* Work on Chrome, Safari, Edge, Opera  Browser*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Work on Firefox Browser */
input[type=number] {
  -moz-appearance: textfield;
}

.contact-us label{
  margin-bottom: 8px;
}

.contact-us a{
  color: inherit;
}

.contact-us button{
  width: 100%;
  margin-top: 32px;
  padding: 12px;
  border: 1px solid #7F56D9;
  background-color: #6061FF;
  color: #fff;
  font-weight: 500;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.form-inputs-wrap{
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.form-inputs-wrap.left{
  margin-right: 36px;
}

.copymark-block{
  padding-right: 10px;
}

.contacts{
  margin: 0 32px;
}

.header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.burger-toggle-button,
.navigation-collapsed{
  display: none !important;
}

.navigation{
  display: flex;
  z-index: 999;
}

.navigation ul{
  display: flex;
}

.navigation-collapsed ul{
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.navigation ul li,
.navigation-collapsed ul li{
  list-style: none;
}

.burgerButtonIcon{
  font-weight: 700;
  font-size: 24px;
}

@media screen and (max-width: 1024px) {
  .calendar-item-padding{
    padding-right: 32px;
  }
  .benefit-item-2{
    padding-top: 104px;
  }
  .benefit-text{
    margin-left: 143px;
  }
  .gifting-calendar-item img{
    width: 75%;
  }
}

@media screen and (max-width: 992px) {
  .main-item h1,
  .features-title h1{
    font-size: 54px;
  }
  .benefit-text{
    margin-left: 100px;
  }
  .footer-contacts-info{
    font-size: 14px;
  }
}

@media screen and (max-width: 850px) {
  .nav-item{
    font-size: 14px;
  }
  .main-item h1{
    font-size: 40px;
  }
  .main-item p{
    font-size: 14px;
  }
  .explore-features-btn{
    font-size: 14px;
    width: 135px;
  }
  .feature-item h1{
    font-size: 28px;
  }
  #download h1{
    font-size: 42px;
  }
}

@media screen and (max-width: 767px) {
  .inner-padding{
    padding: 0 40px 0 64px;
  }
  .main-screen{
    flex-direction: column;
    height: 100%;
    padding-top: 48px;
    padding-left: 0;
  }
  .main-item{
    justify-content: center;
    text-align: center;
    padding: 0 15px;
  }
  .main-item h1{
    margin-bottom: 16px;
  }
  .hide-button{
    display: none;
  }
  .main-item img{
    height: 100%;
    width: 100%;
    margin-top: -90px;
  }
  .features-block{
    padding-bottom: 62px;
  }
  .items-block{
    flex-direction: column;
  }
  .feature-item{
    margin-bottom: 32px;
  }
  .item-img-container{
    width: 175px;
    height: 175px;
  }
  .gifting-calendar{
    flex-direction: column;
  }
  .gifting-calendar-item img{
    width: 70%;
  }
  .gifting-calendar{
    padding-top: 40px;
  }
  .benefit-text{
    margin-left: 0;
    text-align: center;
    padding: 0 30px;
  }
  .calendar-item-padding{
    padding-right: 0;
    margin-bottom: 26px;
  }
  .benefit-2{
    flex-direction: column-reverse;
  }
  .benefit-item-2{
    text-align: center;
    margin-top: 0;
    padding-right: 0;
    padding-top: 0;
  }
  .benefit-text h1{
    margin-bottom: 16px;
  }
  .web-image{
    display: none;
  }
  .mob-image{
    display: block;
  }
  .benefit-2-pb{
    padding-bottom: 0;
  }
  .benefit-2 .benefit-text{
    margin-left: 0;
  }
  #download .gifting-calendar-item{
    align-items: center;
  }
  #download .download-benefit-item{
    text-align: center;
    width: 80%;
    margin-right: 0;
  }
  .apps-block{
    justify-content: center;
  }
  .privacy, .terms{
    max-width: 80%;
  }
  .fs-64-600{
    font-size: 52px;
  }
  .contact-data, .footer-contacts-info{
    flex-direction: column;
    font-size: 16px;
  }
  .footer-contacts-info .flex-center,
  .flex-center.footer-contacts-info{
    align-items: flex-start;
  }
  .contacts{
    margin: 32px 0;
  }
  .copymark-block{
    margin-bottom: 32px;
  }
  .burger-toggle-button,
  .navigation-collapsed{
    display: block !important;
  }
  .navigation{
    display: none;
  }
}

@media screen and (max-width: 560px) {
  #download .gifting-calendar{
    margin-bottom: 48px;
  }
  footer .justify-between,
  footer .nav-links{
    flex-direction: column;
    align-items: flex-start;
  }
  footer .nav-links{
    flex-direction: column-reverse;
  }
  footer .nav-item{
    padding-left: 0;
  }
}

@media screen and (max-width: 500px) {
  .features-block{
    margin-bottom: 54px;
  }
  .main-item h1{
    font-size: 30px;
  }
  .main-item p{
    font-size: 13px;
  }
  .main-item img{
    margin-top: -60px;
  }
  .inner-padding{
    padding: 0 10px 0 24px;
  }
  .navbar img{
    width: 80%;
  }
  .navbar-toggler{
    padding: 2px 6px;
  }
  .features-title h1{
    font-size: 40px;
  }
  .feature-item h1{
    font-size: 24px;
  }
  .item-img-container{
    width: 144px;
    height: 144px;
  }
  .benefit-text h1,
  #download h1{
    font-size: 40px;
  }
  .gifting-calendar-item img{
    width: 80%;
  }
  .gifting-calendar{
    margin-bottom: 54px;
  }
  .calendar-item-padding{
    margin-bottom: 0;
  }
  .contact-us{
    width: 90%;
  }
  .names{
    flex-direction: column;
  }
  .form-inputs-wrap{
    width: 100%;
  }
  .form-inputs-wrap.left{
    margin-right: 0;
  }
  .privacy, .terms{
    max-width: 100%;
    padding: 32px;
  }
  .fs-64-600{
    font-size: 42px;
  }
  .fs-40-600{
    font-size: 36px;
  }
}

@media screen and (max-width: 400px) {
  .appStore{
    margin-right: 0;
  }
  .apps-block{
    flex-direction: column;
    align-items: center;
  }
}
